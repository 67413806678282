module.exports = {
  "account_page": null,
  "shopping_cart_page": null,
  "not_found_page": [
    {
      "language": "fr-FR",
      "slug": "/not-found"
    },
    {
      "language": "en-US",
      "slug": "/en-US/not-found"
    },
    {
      "language": "de-DE",
      "slug": "/de-DE/not-found"
    },
    {
      "language": "it-IT",
      "slug": "/it-IT/not-found"
    }
  ]
}